import React from "react"
import { graphql } from "gatsby"

export default function Products() {
  const items = [
    {
      id: 1,
      image: "",
      text: "Product",
    },
    {
      id: 2,
      image: "",
      text: "Product",
    },
    {
      id: 3,
      image: "",
      text: "Product",
    },
    {
      id: 4,
      image: "",
      text: "Product",
    },
    {
      id: 5,
      image: "",
      text: "Product",
    },
    {
      id: 6,
      image: "",
      text: "Product",
    },
    {
      id: 7,
      image: "",
      text: "Product",
    },
    {
      id: 8,
      image: "",
      text: "Product",
    },
    {
      id: 9,
      image: "",
      text: "Product",
    },
    {
      id: 10,
      image: "",
      text: "Product",
    },
    {
      id: 11,
      image: "",
      text: "Product",
    },
    {
      id: 12,
      image: "",
      text: "Product",
    },
    {
      id: 13,
      image: "",
      text: "Product",
    },
    {
      id: 14,
      image: "",
      text: "Product",
    },
  ]

  return (
    <div className="w-full flex justify-center items-center py-2">
      <div className="grid grid-cols-2 gap-6 w-96">
        {items.map(item => (
          <div
            key={item.id}
            className="relative group bg-gray-200 rounded-lg overflow-hidden shadow-lg"
          >
            <img
              src={item.image}
              alt={item.text}
              className="w-full h-32 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
              <p className="text-white text-center text-sm px-8">
                {item.detail}
              </p>
            </div>
            {/* <div className="p-4">
                <p
                  className="text-center text-xs uppercase"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
